import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import "../styles/markdown.scss"
import SEO from "../components/seo"

export default function Template({
    data,// this prop will be injected by the GraphQL query below.
    location 
}) {
    const { markdownRemark } = data // data.markdownRemark holds your post data
    const { frontmatter, html } = markdownRemark;
    const { background, color } = frontmatter;
    const pageTitle = 'Scyer ' + frontmatter.title;
    return (
    <Layout location={location}>
            <SEO title={pageTitle} keywords={[`scryer`, `software`, `developers`, `development`, `jobs` , `career`]} />
        <div className="blog-post-container" style={{ background, color }}>
            <div className="blog-post">
                <h1>{frontmatter.title}</h1>
                {/* <h2>{frontmatter.date}</h2> */}
                <div
                    className="blog-post-content"
                    dangerouslySetInnerHTML={{ __html: html }}
                />
            </div>
        </div>
    </Layout>
    )
}
export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        background
        color
      }
    }
  }
`